import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'emma-script-injector-amazon-login',
  template: '',
})
export class EMMAScriptInjectorAmazonLoginComponent implements OnInit {
  static inject(): void {
    const script = document.createElement('script');
    script.id = 'amazon-login-sdk';
    script.async = true;
    script.src = 'https://assets.loginwithamazon.com/sdk/na/login1.js';
    document.getElementById('amazon-root')?.appendChild(script);
  }

  ngOnInit(): void {
    setTimeout(() => EMMAScriptInjectorAmazonLoginComponent.inject(), 100);
  }
}
